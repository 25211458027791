import { connect } from 'react-redux';
import {
  changeAndUpdateLowerFields, clearResults, fillManualLink, buttonReset, getNextData
} from '../actions';
import DropDown from '../../../common/components/dropdown';
import { fieldNames } from '../../../common/constants';

function mapStateToProps(state) {
  const pageName = state.configuration.translationsPageName.makemodel.pageName;
  return {
    label: state.translations[pageName].data.fuelLabel,
    name: fieldNames.fuel,
    componentState: state.makeModelData.fuel,
    defaultText: state.translations[pageName].data.pleaseChoose,
    options: state.makeModelData.fuel.options
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChange: (e) => {
      dispatch(changeAndUpdateLowerFields(e.target.value, fieldNames.fuel));
      if (e.target.value !== '0') {
        dispatch(getNextData(fieldNames.fuel));
        dispatch(fillManualLink());
      }
      dispatch(clearResults());
      dispatch(buttonReset());
    }
  };
}
const Fuel = connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDown);

export default Fuel;
