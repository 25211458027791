import React from 'react';
import parse from 'html-react-parser';
import { PropTypes } from 'prop-types';
import { featureEnabled } from '../../helpers/features';
import { datSupportedMakesLightBoxId } from '../../common/constants';
import SupportedMakesBox from './elements/datSupportedMakesBox';
import { eventTrack } from '../../tracking/tracking';
import LoadingSpinner from '../../common/components/loadingSpinner';

const Vin = ({
               vinData,
               onSubmit,
               success,
               errorOccurred,
               isLoading,
               onVinChange,
               translations,
               listingFormUrl
             }) => {
  function trackManualLinkClick() {
    eventTrack('myarea_listing_creation', 'listing_manual');
    return true;
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (vinData.isDisabled) return;
    onSubmit(vinData.number);
  };

  const errorMessage = () => {
    if (vinData.vinError === '') return null;
    return <p id="vin-validation-error" className="lc-error sc-font-s lc-margin-top-s">{vinData.vinError}</p>;
  };

  const supportedMakesClassNames = () => {
    let classNames = 'lc-padding-top-l positionRelative';
    if (success) classNames += ' sc-hidden';
    return classNames;
  };

  const submitButton = () => {
    let className = 'sc-btn-bob sc-btn-block sc-block-center lc-margin-top-l';
    if (success) className += ' sc-hidden';
    if (isLoading) className += ' lc-icon-wait';

    const textStyle = isLoading ? { opacity: 0 } : {};

    return (
      <button id="submit-vin" className={className} disabled={vinData.isDisabled}>
        <span style={textStyle}>{translations.vinform.data.submit}</span>
      </button>
    );
  };

  const inputField = () => {
    let className = 'sc-input lc-margin-top-xs';
    if (errorOccurred) className += ' error';
    if (success) className += ' success lc-checkmark-icon';

    const onChange = (e) => {
      if (success) return;
      onVinChange(e.target.value);
    };

    // AS24-25967
    // AC: onChange and onInput trigger the vin update action twice
    // This is not crucial for this specific field

    return (
      <input
        type="text"
        id="vin-input"
        name="number"
        maxLength={17}
        size="17"
        placeholder={translations.vinform.data.vinPlaceholder}
        className={className}
        value={vinData.number}
        onChange={onChange}
        onInput={onChange}
      />
    );
  };
  if (featureEnabled('datVinServiceTemporary')) {
    let className = 'sc-btn-bob sc-btn-block sc-block-center lc-margin-top-l';
    if (success) className += ' sc-hidden';
    const textStyle = isLoading ? { opacity: 0.1 } : {};
    const isDisabled = isLoading ? 'disabled' : '';
    return (
      <div className="sc-grid-row ">

        {(translations.vinform.data.vinLookupMaintenanceHeader && translations.vinform.data.vinLookupMaintenanceText)
          ? (
            <div className="lc-vin-message sc-as24-notification show" type="information" style={{ marginBottom: '1em' }}>
              <div className="icon">
                <div>
                  <span className="sc-font-m sc-font-bold">{parse(translations.vinform.data.vinLookupMaintenanceHeader)}</span>
                  <div>
                    <p>
                      {parse(translations.vinform.data.vinLookupMaintenanceText)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        }

        <div className="sc-grid-col-3 sc-hidden-s" />
        <form
          id="vin-form"
          className="sc-clearfix sc-block-center lc-padding-horizontal-l lc-padding-bottom-xxl sc-grid-col-6 sc-grid-col-s-12"
          onSubmit={handleOnSubmit}
        >
          <div className="sc-font-s sc-text-left lc-padding-bottom-xxl">{translations.vinform.data.datLegalDisclaimer}</div>
          <p id="vin-input-label">{translations.vinform.data.vinLabel}</p>
          {inputField()}
          {errorMessage()}
          <button id="submit-vin" className={className} disabled={vinData.isDisabled}>
            <span>{translations.vinform.data.submit}</span>
          </button>
          <div id="supportedMakes" className={supportedMakesClassNames()}>
            <div style={textStyle} className="sc-absolute-center">
              <a className={isDisabled} data-lightbox-open={datSupportedMakesLightBoxId}>{translations.vinform.data.datSupportedMakesLinkText}</a>
            </div>
            <LoadingSpinner isLoading={isLoading} message={translations.vinform.data.datWaitMessage} />
          </div>
        </form>
        <div className="sc-grid-row">
          <div id="create-manually-vin" className="lc-create-manually sc-text-center sc-grid-col-12" style={textStyle}>
            <p>{translations.vehicle.data.listManuallyTitle}</p>
            <a
              className={isDisabled}
              id="manual-link-vin"
              href={listingFormUrl}
              onClick={trackManualLinkClick}
            >
              {translations.vehicle.data.listManuallyLink}
            </a>
          </div>
        </div>
        <SupportedMakesBox />
      </div>
    );
  }
  return (
    <div className="sc-grid-row ">
      <div className="sc-grid-col-3 sc-hidden-s" />
      <form
        id="vin-form"
        className="sc-clearfix sc-block-center lc-padding-horizontal-l lc-padding-bottom-xxl sc-grid-col-6 sc-grid-col-s-12"
        onSubmit={handleOnSubmit}
      >
        <p id="vin-input-label">{translations.vinform.data.vinLabel}</p>
        {inputField()}
        {errorMessage()}
        {submitButton()}
        <div id="supportedMakes" className={supportedMakesClassNames()}>
          <p className="sc-text-center lc-padding-top-l sc-font-s sc-font-silent">
            {translations.vinform.data.supportedMakes}
          </p>
          <p className="sc-text-center sc-font-s sc-font-silent">
            {translations.vinform.data.supportedMakesList}
          </p>
        </div>
      </form>
    </div>
  );
};

Vin.propTypes = {
  vinData: PropTypes.object.isRequired,
  errorOccurred: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onVinChange: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
  listingFormUrl: PropTypes.string.isRequired
};

export default Vin;
