export const CHANGE_AND_UPDATE_LOWER_FIELDS = 'CHANGE_AND_UPDATE_LOWER_FIELDS';
export const MAKES_REQUEST = 'MAKES_REQUEST';
export const MAKES_SUCCESS = 'MAKES_SUCCESS';
export const MAKES_FAILURE = 'MAKES_FAILURE';
export const TAXONOMY_SUCCESS = 'TAXONOMY_SUCCESS';
export const TAXONOMY_RESULT_SUCCESS = 'TAXONOMY_RESULT_SUCCESS';
export const TAXONOMY_FAILURE = 'TAXONOMY_FAILURE';
export const SHOW_FIRST_REG = 'SHOW_FIRST_REG';
export const SHOW_COLOR = 'SHOW_COLOR';
export const SHOW_EQUIPMENT_LINE = 'SHOW_EQUIPMENT_LINE';
export const CHANGE_FIRST_REG_AND_UPDATE_LOWER_FIELDS = 'CHANGE_FIRST_REG_AND_UPDATE_LOWER_FIELDS';
export const COLORS_REQUEST = 'COLORS_REQUEST';
export const COLORS_SUCCESS = 'COLORS_SUCCESS';
export const COLORS_FAILURE = 'COLORS_FAILURE';
export const BUTTON_REDIRECT = 'BUTTON_REDIRECT';
export const BUTTON_RESET = 'BUTTON_RESET';
export const VEHICLE_OFFER_TYPES_SUCCESS_MAKE_MODEL = 'VEHICLE_OFFER_TYPES_SUCCESS_MAKE_MODEL';
export const VEHICLE_OFFER_TYPES_REQUEST_MAKE_MODEL = 'VEHICLE_OFFER_TYPES_REQUEST_MAKE_MODEL';
export const CHANGE_VEHICLE_OFFER_TYPE_MAKE_MODEL = 'CHANGE_VEHICLE_OFFER_TYPE_MAKE_MODEL';
export const VEHICLE_OFFER_TYPES_FAILURE_MAKE_MODEL = 'VEHICLE_OFFER_TYPES_FAILURE_MAKE_MODEL';
export const FIRST_REG_CHANGE_MAKE_MODEL = 'FIRST_REG_CHANGE_MAKE_MODEL';
export const FIRST_REG_CHANGE_VALIDATION_MAKE_MODEL = 'FIRST_REG_CHANGE_VALIDATION_MAKE_MODEL';
export const SHOW_FIRST_REG_MAKE_MODEL = 'SHOW_FIRST_REG_MAKE_MODEL';
export const CHANGE_BUILD_RANGE = 'CHANGE_BUILD_RANGE';
